import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import SEO from "../../components/seo"

import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import Header from  "../../components/Common/Header"; 

import People from  "../../components/modules/people"; 
import {config} from '../../components/Common/constant';

import { GET_ARTICLE } from "../../queries/common_use_query";
import Case_Studies from  "../../components/modules/sell-with-confidence"; 
import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {

    const context = useContainerContext();
    const { property_data } = context.state;
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('client-service-manager');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
      <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

      <div className="pre-wrapper">
        <Header />

        {property_data && <People property_data={property_data}  /> }

        <NavLink tab_name="People" prevLink={config.preval_casestudies}  nextLink={config.preval_marketing} />
        {/* footer-wrapper */}
      </div>
    </>
    // pre-wrapper
}

export default PresentationPage
