import React,{useState} from "react"
import {Container,Navbar,Nav, Button} from 'react-bootstrap';
import { Link } from "gatsby"
import VideoImg2 from "../../assests/images/video2.jpg";
import VideoImg3 from "../../assests/images/video3.jpg";
import VideoImg4 from "../../assests/images/video4.jpg";
import rightArw from "../../assests/images/right-arrow.svg";
import leftArw from "../../assests/images/left-arrow.svg";

import ItemsCarousel from 'react-items-carousel';
import FsLightbox from 'fslightbox-react';

import {youtube_parser, useWindowDimensions} from "../../components/utils/common"

// import ModalVideo from 'react-modal-video'
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';

import VideoModule from "./video-module"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function CSMTestimonials(props){
    var SlideImages = [], csm = '', lightImages2 = [];
    const { height, width } = useWindowDimensions();

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [open_modal, setOpenModal] = useState(false);
    const [video_id, setVideoId] = useState('');

    const [playing, setPlaying] = useState(false);
    const [play_index, setPlayIndex] = useState();
    const [pause_index, setPauseIndex] = useState();

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 0
    });

    const openLighboxMobile = (e, ind, video_url) => {
        e.preventDefault();
        if(width > 767){
          var youtube_id = youtube_parser(video_url);
          setVideoId(youtube_id);
          setTimeout(function(){
            setOpenModal(true);
          },500)
        } else{
          setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
          });
        }        
    }

    const openModel = (video_url) => {
      var youtube_id = youtube_parser(video_url);
      setVideoId(youtube_id);
      setTimeout(function(){
        setOpenModal(true);
      },500)
    }

    const playVideo = (index) => {
      //e.preventDefault();
      setPlayIndex(index);
      setPauseIndex();
      
    }
    const pauseVideo = (index) => {
      //e.preventDefault();
      setPauseIndex(index);
      setPlayIndex();
    }

    var csm = props.user_data;
    

    if(csm){
      var video_testimonial1 = csm.VideoTestimonial1 ? csm.VideoTestimonial1.replace('https://youtu.be/', 'https://www.youtube.com/watch?v=') : '';
      var video_testimonial2 = csm.VideoTestimonial2 ? csm.VideoTestimonial2.replace('https://youtu.be/', 'https://www.youtube.com/watch?v=') : '';
      var video_testimonial3 = csm.VideoTestimonial3 ? csm.VideoTestimonial3.replace('https://youtu.be/', 'https://www.youtube.com/watch?v=') : '';

      var video_img1 = csm.VideoTestimonial1 ? 'https://i3.ytimg.com/vi/'+youtube_parser(csm.VideoTestimonial1)+'/maxresdefault.jpg' : '';
      var video_img2 = csm.VideoTestimonial2 ? 'https://i3.ytimg.com/vi/'+youtube_parser(csm.VideoTestimonial2)+'/maxresdefault.jpg' : '';
      var video_img3 = csm.VideoTestimonial3 ? 'https://i3.ytimg.com/vi/'+youtube_parser(csm.VideoTestimonial3)+'/maxresdefault.jpg' : '';

      SlideImages = [{'src_url': video_img1, 'title': csm.VideoTestimonialClient1, 'video_url': video_testimonial1+"?autoplay=1", 'type': 'video'}, {'src_url': video_img2, 'title': csm.VideoTestimonialClient2, 'video_url': video_testimonial2+"?autoplay=1", 'type': 'video'}, {'src_url': video_img3, 'title': csm.VideoTestimonialClient3, 'video_url': video_testimonial3, 'type': 'video'}];

      lightImages2 = SlideImages && SlideImages.map(item => item.video_url ? item.video_url : '');
    }
    

    //console.log("SlideImages", SlideImages, lightImages2);

    var centerMode = width < 800 ? false : true;
    var showIndicators = width < 800 ? true : false;
    var showArrows = width < 800 ? false : SlideImages.length > 3 ? true : false;

    console.log("csm", csm);

    //console.log("playing", playing);

    //console.log("playing", playing, play_index, pause_index);

    return (
        <>
        <div className="customer-wrapper">
        <Container>
          <div className="customer-sec">
            <h3>What {csm && csm.Name ? csm.Name.split(' ')[0] : ''}’s customers think?</h3>

            <FsLightbox
              toggler={lightboxController.toggler}
              sources={lightImages2}
              slide={lightboxController.slide}
            />

          <div className={'sliderCarousel testi_slider'}>
            <Carousel 
              autoPlay={false} 
              interval={3000000} 
              infiniteLoop={false} 
              showArrows={showArrows} 
              showIndicators={showIndicators} 
              stopOnHover={false} 
              swipeable={true} 
              transitionTime={1000} 
              showStatus={false} 
              selectedItem={0} 
              showThumbs={false}
              centerMode={centerMode}
              centerSlidePercentage={100/3}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <Button shape="circle" className="prev_arw" variant="default" onClick={onClickHandler} title={label}><img src={leftArw} alt="Prev" title="" /></Button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <Button shape="circle" className="next_arw" variant="default" onClick={onClickHandler} title={label}><img src={rightArw} alt="Next" title="" /></Button>
                )
              }>

                {
                  SlideImages.map((item, index) => {
                    return(
                    <div key={1} className="carousalItem">
                      <div className="customer-block">
                          
                            <div className="customer-card">
                              <div className="video-wrap">
                                {
                                  item.src_url ? (
                                    // <figure>
                                    //   <img src={item.src_url} alt="video-img" />
                                    // </figure>
                                    <>
                                      <div className={`video_overlay ${(index === play_index && pause_index !== play_index) ? 'hide' : ''}`} onClick={() => playVideo(index)}></div>
                                      <VideoModule video_url={item.video_url} playing={index === play_index} loop={true} width={'100%'} height={'100%'} onPlay={() => playVideo(index)} onPause={() => pauseVideo(index)} onEnded={() => pauseVideo(index)}/> 
                                    </>
                                  ) : (
                                    <span className="coming_soon">Video Coming Soon</span>
                                  )
                                }
                                
                                {/* {
                                  item.video_url && (
                                    <a href="javasdccript:;">
                                      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0)">
                                      <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                                      </g>
                                      <defs>
                                      <clipPath>
                                        <rect width="22" height="25.2703" fill="white"/>
                                      </clipPath>
                                      </defs>
                                      </svg>
                                    </a>
                                  )
                                } */}
                                
                              </div>
                            {/* video-wrap */}
                            <div className="customer-name">
                              <h3>{item.title}</h3>
                            </div>
                            {/* customer-name */}
                            </div>
                            {/* customer-card */}
                          
                      </div>
                    </div>
                    )                        
                  })
                }                
              </Carousel>

            </div>

            {/* <ModalVideo channel="youtube" autoplay mute={1} isOpen={open_modal} videoId={video_id} onClose={() => setOpenModal(false)} /> */}

          </div>
          {/* customer-sec */}
        </Container>
      </div>
      {/* customer-wrapper */}
    </>
    )
}

export default CSMTestimonials
