import React, {useState} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import VideoImg1 from "../../assests/images/video1.jpg"; 

import CustomerVideos from  "./customer-videos"; 

import PeopleJoureny from  "../../pages/preval-presentation/people-journey"; 
import VideoModule from "../../components/modules/video-module"

import { useContainerContext } from "../../store/ContainerContext";
import {youtube_parser, useWindowDimensions} from "../../components/utils/common"

import TimeTrackerComp from "./time_tracker";

const PeopleModule = (props) => {

    const context = useContainerContext();
    const [ property_data, setPropertyData ] = useState(props.property_data);
    const [ user_data, setUserData ] = useState(props.user_data);

    const [ playVideo, setPlayVideo ] = useState(false);

    const { height, width } = useWindowDimensions();


   //console.log("user_data", user_data);
    return <>
      <TimeTrackerComp TabName={'People'} />

      <div className="section-wrapper">
          <Container>
            <h2>About Me
                {/* <div className={"title_nav"}>
                    <Link to="../people-journey" className="right-icon" title="Next">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill="#67707B"/>
                        </svg>
                    </Link>
                </div> */}
            </h2>
            <div className="employee-wrapper">
              {property_data && property_data.property_marketing_director && (
                <>
                  
                  <div className="employment-right people_img">
                    <div className="video-wrap">
                    {
                        playVideo ? (
                        <VideoModule video_url={property_data.property_marketing_director.PMDIntroVideo} playing={true}/>
                        ) : (
                          <>
                          {property_data.property_marketing_director.Photo || property_data.property_marketing_director.PMDIntroVideo ? (
                            <>
                            <figure>
                              {/* <img src={user_data.photo} alt="video-img" /> */}
                              <div className="introImg" style={{backgroundImage:`url("${property_data.property_marketing_director.Photo}")`}}></div>
                              {/* {
                                property_data.property_marketing_director.PMDIntroVideo ? (
                                  <div className="introImg" style={{backgroundImage:`url('https://i3.ytimg.com/vi/${youtube_parser(property_data.property_marketing_director.PMDIntroVideo)}/maxresdefault.jpg')`}}></div>
                                ) : (
                                  <div className="introImg" style={{backgroundImage:`url("${property_data.property_marketing_director.Photo}")`}}></div>
                                )
                              } */}
                              
                            </figure>

                            {/* {
                              property_data.property_marketing_director.PMDIntroVideo && (
                                <a href="javascript:;" onClick={() => setPlayVideo(true) }>
                                  <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0)">
                                  <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                                  </g>
                                  <defs>
                                  <clipPath>
                                  <rect width="22" height="25.2703" fill="white"/>
                                  </clipPath>
                                  </defs>
                                  </svg>
                                </a>
                              )
                            } */}
                            </>
                          ) : (
                            <span className="coming_soon">Video Coming Soon</span>   
                          )
                        }
                        </>
                                                    
                        )                        
                      }
                      </div>
                    </div>
                    {/* video-wrap */}


                  <div className="employee-content">
                    <div className="employee-top">
                      <h3>{property_data.property_marketing_director.Name}</h3>
                      <span>{property_data.property_marketing_director.Designation}</span>
                    </div>
                    {/* top-content */}
                    {/* <div className="review-wrapper">
                        <ul className="rating-wrap">
                          <li>  
                            <Link to="#">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.8092 8.98381C21.8092 8.47406 21.3824 8.06039 20.8552 8.06039C20.8212 8.06039 20.7879 8.06225 20.755 8.0652L14.1665 7.90982L11.9158 2.03403C11.8023 1.64737 11.4357 1.36424 11.0001 1.36424C10.5641 1.36424 10.198 1.64737 10.084 2.03403L7.83283 7.90982L1.24472 8.0652C1.21166 8.06194 1.17798 8.06039 1.14445 8.06039C0.61778 8.05993 0.190918 8.47375 0.190918 8.98381C0.190918 9.30978 0.364922 9.59508 0.627404 9.75946L5.87501 13.4693L4.00256 19.2892C3.93457 19.4154 3.89577 19.5594 3.89577 19.7119C3.89577 20.2221 4.32263 20.6357 4.84977 20.6357C5.07298 20.6357 5.27911 20.5606 5.44147 20.4355L11.0006 16.9062L16.5594 20.4355C16.7223 20.5606 16.9275 20.6357 17.1512 20.6357C17.6778 20.6357 18.1047 20.2221 18.1047 19.7119C18.1047 19.5599 18.0659 19.416 17.9979 19.2892L16.1256 13.4693L21.373 9.75946C21.6349 9.59477 21.8092 9.30931 21.8092 8.98381Z" fill="#CC0133"/>
                            </svg>
                            </Link>
                          </li>
                          <li>  
                            <Link to="#">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.8092 8.98381C21.8092 8.47406 21.3824 8.06039 20.8552 8.06039C20.8212 8.06039 20.7879 8.06225 20.755 8.0652L14.1665 7.90982L11.9158 2.03403C11.8023 1.64737 11.4357 1.36424 11.0001 1.36424C10.5641 1.36424 10.198 1.64737 10.084 2.03403L7.83283 7.90982L1.24472 8.0652C1.21166 8.06194 1.17798 8.06039 1.14445 8.06039C0.61778 8.05993 0.190918 8.47375 0.190918 8.98381C0.190918 9.30978 0.364922 9.59508 0.627404 9.75946L5.87501 13.4693L4.00256 19.2892C3.93457 19.4154 3.89577 19.5594 3.89577 19.7119C3.89577 20.2221 4.32263 20.6357 4.84977 20.6357C5.07298 20.6357 5.27911 20.5606 5.44147 20.4355L11.0006 16.9062L16.5594 20.4355C16.7223 20.5606 16.9275 20.6357 17.1512 20.6357C17.6778 20.6357 18.1047 20.2221 18.1047 19.7119C18.1047 19.5599 18.0659 19.416 17.9979 19.2892L16.1256 13.4693L21.373 9.75946C21.6349 9.59477 21.8092 9.30931 21.8092 8.98381Z" fill="#CC0133"/>
                            </svg>
                            </Link>
                          </li>
                          <li>  
                            <Link to="#">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.8092 8.98381C21.8092 8.47406 21.3824 8.06039 20.8552 8.06039C20.8212 8.06039 20.7879 8.06225 20.755 8.0652L14.1665 7.90982L11.9158 2.03403C11.8023 1.64737 11.4357 1.36424 11.0001 1.36424C10.5641 1.36424 10.198 1.64737 10.084 2.03403L7.83283 7.90982L1.24472 8.0652C1.21166 8.06194 1.17798 8.06039 1.14445 8.06039C0.61778 8.05993 0.190918 8.47375 0.190918 8.98381C0.190918 9.30978 0.364922 9.59508 0.627404 9.75946L5.87501 13.4693L4.00256 19.2892C3.93457 19.4154 3.89577 19.5594 3.89577 19.7119C3.89577 20.2221 4.32263 20.6357 4.84977 20.6357C5.07298 20.6357 5.27911 20.5606 5.44147 20.4355L11.0006 16.9062L16.5594 20.4355C16.7223 20.5606 16.9275 20.6357 17.1512 20.6357C17.6778 20.6357 18.1047 20.2221 18.1047 19.7119C18.1047 19.5599 18.0659 19.416 17.9979 19.2892L16.1256 13.4693L21.373 9.75946C21.6349 9.59477 21.8092 9.30931 21.8092 8.98381Z" fill="#CC0133"/>
                            </svg>
                            </Link>
                          </li>
                          <li>  
                            <Link to="#">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.8092 8.98381C21.8092 8.47406 21.3824 8.06039 20.8552 8.06039C20.8212 8.06039 20.7879 8.06225 20.755 8.0652L14.1665 7.90982L11.9158 2.03403C11.8023 1.64737 11.4357 1.36424 11.0001 1.36424C10.5641 1.36424 10.198 1.64737 10.084 2.03403L7.83283 7.90982L1.24472 8.0652C1.21166 8.06194 1.17798 8.06039 1.14445 8.06039C0.61778 8.05993 0.190918 8.47375 0.190918 8.98381C0.190918 9.30978 0.364922 9.59508 0.627404 9.75946L5.87501 13.4693L4.00256 19.2892C3.93457 19.4154 3.89577 19.5594 3.89577 19.7119C3.89577 20.2221 4.32263 20.6357 4.84977 20.6357C5.07298 20.6357 5.27911 20.5606 5.44147 20.4355L11.0006 16.9062L16.5594 20.4355C16.7223 20.5606 16.9275 20.6357 17.1512 20.6357C17.6778 20.6357 18.1047 20.2221 18.1047 19.7119C18.1047 19.5599 18.0659 19.416 17.9979 19.2892L16.1256 13.4693L21.373 9.75946C21.6349 9.59477 21.8092 9.30931 21.8092 8.98381Z" fill="#CC0133"/>
                            </svg>
                            </Link>
                          </li>
                          <li>  
                            <Link to="#">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.8092 8.98381C21.8092 8.47406 21.3824 8.06039 20.8552 8.06039C20.8212 8.06039 20.7879 8.06225 20.755 8.0652L14.1665 7.90982L11.9158 2.03403C11.8023 1.64737 11.4357 1.36424 11.0001 1.36424C10.5641 1.36424 10.198 1.64737 10.084 2.03403L7.83283 7.90982L1.24472 8.0652C1.21166 8.06194 1.17798 8.06039 1.14445 8.06039C0.61778 8.05993 0.190918 8.47375 0.190918 8.98381C0.190918 9.30978 0.364922 9.59508 0.627404 9.75946L5.87501 13.4693L4.00256 19.2892C3.93457 19.4154 3.89577 19.5594 3.89577 19.7119C3.89577 20.2221 4.32263 20.6357 4.84977 20.6357C5.07298 20.6357 5.27911 20.5606 5.44147 20.4355L11.0006 16.9062L16.5594 20.4355C16.7223 20.5606 16.9275 20.6357 17.1512 20.6357C17.6778 20.6357 18.1047 20.2221 18.1047 19.7119C18.1047 19.5599 18.0659 19.416 17.9979 19.2892L16.1256 13.4693L21.373 9.75946C21.6349 9.59477 21.8092 9.30931 21.8092 8.98381Z" fill="#CC0133"/>
                            </svg>
                            </Link>
                          </li>
                        </ul>
                        
                        <span>Rated <strong>-</strong> by - Reviews</span>
                    </div> */}
                    {/* review-wrapper */}
                    {property_data.property_marketing_director.Bio && <p>{property_data.property_marketing_director.Bio}</p>}
                    
                    {/* <div className="content-block">
                      <strong>Location:</strong>
                      <span>-</span>
                    </div> */}
                    {/* content-block */}
                    {
                      property_data.property_marketing_director.Telephone && (
                        <div className="content-block">
                          <strong>Telephone:</strong>
                          <a href={`tel:${property_data.property_marketing_director.Telephone}`}>{property_data.property_marketing_director.Telephone}</a>
                        </div>
                      )
                    }                        
                    {/* content-block */}

                    {
                      user_data.email && (
                        <div className="content-block">
                          <strong>Email Address:</strong>
                          <a href={`mailto:${property_data.property_marketing_director.Email}`}>{property_data.property_marketing_director.Email}</a>
                        </div>
                      )
                    }                        
                    {/* content-block */}

                  </div>
                  {/* employment-content */}
                </>
                )
              }              
            </div>
            {/* employee-wrapper */}
          </Container>
      </div>
      {/* section-wrapper */}

      

      <CustomerVideos user_data={property_data && property_data.property_marketing_director} />

      <PeopleJoureny property_data={props.property_data} />
      {/* section-wrapper */}

      

    </>
}

export default PeopleModule
